.password-strength-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -20px;
    margin-bottom: 10px;
}

.strength-bar-outline {
    width: 33%;
    height: 11px;
    border: 2px solid grey;
    border-radius: 6px;
    overflow: hidden;
}

.strength-bar {
    height: 10px;
}

.strength-text {
    font-weight: bold;
}
  