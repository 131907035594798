#CircleStatusWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    width: 33svw;
}

#CircleStatusArea {
    display: flex;
}

#CircleStatusAndText {
    display: flex;
    flex-direction: column;

}

#CircleStatus {
    height: 12px;
    width: 12px;
    background-color: black;
    border-radius: 50%;
    border-style: solid;
    border-width: 1px;
}

#Line {
    width: 250px;
    height: 2px;
    background-color: black;
}

.StatusText {
    margin-top: 5px;
    font-size: 2svh;
    margin-left: -10svh;
}
