#ApplicantsPage {
    height: 100vh;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
}

#ApplicantsPage #ApplicantsWrapper {
    /* border-style: solid; */
    /* border-width: .5px; */
    padding: 2.5%;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    /* justify-content: center; */
    width: 100%;
    height: 80svh;
}

#LeftApplicants {
    display: flex;
    flex-direction: column;
    width: 33%;
    border-right-style: solid;
    border-width: 0.25px;
    margin-right: 2.5%;
    padding-right: 2.5%;
    border-color: #00000050;
}

#ApplicantsMap #name:hover{
    color: #34AED4;
}

#ApplicantsPage #ApplicantHeader {
    /* text-align: center; */
}

#ApplicantsTopArea {
    font-size: 2svh;
    margin-bottom: 1%;
    text-align: left;
}

.ApplicantsDescription {
    font-size: 2.5svh;
    margin-bottom: 2.5%;
    line-height: 2.5svh;
    padding-right: 2.5%;
}

#ApplicantsLayout {
    width: 63.33%;
    display: flex;
    flex-direction: column;
}

#ApplicantPreview {
    margin: 0px auto 10px auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    border: solid 1px black;
    border-radius: 10px;
    margin-top: 2.5%;
    height: 20svh;
    padding: 1.25%;
    justify-content: space-between;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    overflow: hidden;
    transition: height .5s cubic-bezier(0.19, 1, 0.22, 1);

}

#ApplicantsMap {
    color: black;
    font-size: 2.25svh;
    /* margin-block: 0; */
    margin-left: 1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#ApplicantPreview.expanded {
    height: 32.5svh;
    max-height: 350px;
    transition: height .3s cubic-bezier(0.19, 1, 0.22, 1);
    /* Height when expanded */
}

.applicantexpand {
    margin-top: 2.5%;
    display: none;
    /* Initially hidden */
    opacity: 0;
    transition: opacity 0.5s ease;
}

.applicantexpand.visible {
    display: block;
    /* Make visible */
    opacity: 1;
}

#ApplicantsMap h4,
h5 {
    margin: 0;
}

#ApplicantsMap .applicantbio {
    color: var(--dark);
    font-weight: 300;
}

#AcceptApplicant {
    cursor: pointer;
    background-color: #4CAF50;
    color: white;
    width: 20svw;
    display: flex;
    justify-content: center;
    padding: 1%;
    border-radius: 10px;
}

.applicantexpand h5 {
    font-size: 14px;
}

.applicantexpand .userinfo {
    font-size: 16px;
    margin: 0 0 5px 0px;
    padding: 0;
    color: var(--dark);
    font-style: italic;
    text-align: center;
}

.applicantexpand h5 span {
    color: var(--main);
    font-weight: 600;
}

.acceptreject {
    display: flex;
    gap: 5%;
    border-top: solid 2px black;
    padding: 1%;
    width: 100%;
    align-self: center;
    justify-content: center;
    margin-top: 1%;
}

.acceptreject a:nth-child(1):hover {
    background-color: #C6282890 !important;
    transition: .6s ease;
}

.acceptreject a:nth-child(2):hover {
    background-color: #4CAF5090 !important;
    transition: .6s ease;
}

.applicantlink {
    display: flex;
}

.applicantlink img {
    height: 10svh;
    width: 10svh;
    border-radius: 10px;
}