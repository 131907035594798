.Home {
    /* max-width: 1920px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
}

.HomeIntro {
    width: 100%;
    max-width: 1920px;
    height: 75vh;
    display: flex;
    flex-direction: row;
}

.HomeIntro #lefthome {
    width: 52.5%;
}

.HomeIntro #righthome {
    width: 47.5%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 2.5%;
    /* border-top-right-radius: 0; */
    /* border-top-left-radius: 0; */
}

#righthome img {
    width: 72.5%;
    object-fit: contain;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 20px;
}

.HomeIntro #lefthome {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5%;
}

.HomeIntro #lefthome h1,
h2,
h3 {
    margin: .5%;
    margin-bottom: 2.25%;
}

.HomeIntro h1 {
    font-weight: 400;
    font-size: 2.66em;
    
}

.HomeIntro h2 {
    font-weight: 400;
    font-size: 1.55em;
    line-height: 1.5em;
    /* text-align: justify; */
}

.HomeIntro h3 {
    font-weight: 400;
    font-size: 1.267em;
    line-height: 1.88em;
    /* text-align: justify; */
    /* letter-spacing: -0.25px; */
}

.HomeIntro .HomeText {
    margin-right: 2.5%;
}

.HomeIntro .blue {
    color: var(--main);
    font-family: 'Days One', sans-serif !important;
    user-select: none;
    -webkit-user-select: none;
}


.HomeIntroDesign {
    width: 90%;
}

#DisplayHomeButtons {
    display: flex;
    gap: 5%;
    margin-top: 2.5%;
}

#DisplayHomeButtons #HomeButtonIntern {
    background-color: var(--main);
    color: white;
    padding: 1.25%;
    width: 27.5%;
    text-align: center;
    border-radius: 30px;
    font-weight: 600;
}

#DisplayHomeButtons #HomeButtonFreelancer {
    color: var(--main);
    background-color: var(--whiteColor);
    padding: 1.25%;
    width: 27.5%;
    text-align: center;
    border-radius: 30px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
}


.ServiceTitle {
    font-weight: 700;
    padding-left: 1%;
    margin-bottom: 1%;
}

#PartnersHeader {
    font-size: 2.44em;
    color: var(--main);
}

#DisplayCompanies {
    display: flex;
    flex-direction: row;
    padding-left: 1%;
    margin-bottom: 2.5%;
}

#PartnersLeft {
    width: 50%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    /* text-align: justify; */
    padding: 5%;
}

#PartnersText {
    font-size: 1.11em;
    line-height: 1.99em;

}

.PopularServices {
    max-width: 1920px;
    width: 50%;
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    justify-content: center;
    align-items: center;
    margin-top: 1.25%;
}

.PopularServices .Service {
    /* height: 22.5svh; */
    width: 15svw;
    max-height: 25svh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 2.5%;
    flex: auto;
}

.PopularServices .Service .ServiceCategory {
    border-bottom: 0;
}

.PopularServices .Service h3 {
    font-weight: 400;
}

.PopularServices .Service img {
    width: 12.5svw;
    margin-bottom: 5%;
    transition: filter 0.5s ease;
    /* Smooth transition */
    filter: grayscale(100%);

}

.PopularServices .Service img:hover {
    filter: grayscale(0%);

}

.WrapperHomeButtons {
    margin-top: 2.5%;
    display: flex;
    gap: 2.5%;
    justify-content: flex-start;
}

.GetStartedButton{
    background-color: var(--main);
    text-decoration: none;
    width: 33%;
    height: 6.25svh;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: white;
    align-self: center;
}

.GetStartedButton:hover{
    background-color: var(--second);
    color: var(--whiteColor);
    transition: .6s;
}

#viewForas {
    color: var(--main);
    background-color: var(--whiteColor);
    border: solid 2px var(--main);
}

#viewForas:hover {
    background-color: var(--second);
    color: var(--whiteColor);
    border: solid 2px var(--second);
}

.ArrowButton {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    font-weight: 900;
    font-size: 16px;
}

#LeftArrowButton{
    left: 0;
    right:auto;
}


@media only screen and (max-width: 1200px) {
    .HomeIntro {
        margin-top: 10%;
    }

}


@media only screen and (max-width: 1024px) {

    .Home {
        padding: 2.5%;
        padding-bottom: 25%;
    }

    .HomeIntro h1 {
        font-weight: 400;
        font-size: 1.66em;
    }

    .HomeIntro h2 {
        font-weight: 400;
        font-size: 2.5svh;
    }

    .HomeIntro h3 {
        font-weight: 400;
        font-size: 1.25svh;
        text-align: left;
    }

    .Home .HomeText {
        margin-top: 2.5%;
    }

    .Home .HomeText h1 {
        font-size: 40px;
        text-align: center;
        padding-top: 2%;
    }

    .PopularServices .Service {
        margin-right: 1.25%;
        margin-left: 1.25%;
        height: 12.5svh;
        width: 12.5svh;
        position: relative;
    }

    .PopularServices .Service h3 {
        font-weight: 400;
        font-size: 12px;
    }

    .PopularServices .Service .ServiceCategory {}

    .ServiceTitle {
        font-weight: 700;
        text-align: center;
        font-size: 2svh !important;
    }

    .PopularServices .Service {
        height: 4vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

    }

    .PopularServices .Service img {
        width: 10svh;
        height: 10svh;
        object-fit: contain;
    }

    #PartnersHeader {
        font-size: 2em;
    }

    #DisplayHomeButtons #HomeButtonIntern {
        width: 50%;
    }

    #DisplayHomeButtons #HomeButtonFreelancer {
        width: 50%;
    }

}


@media only screen and (max-width: 768px) {
    .Home {
        overflow-x: hidden;
    }

    #DisplayCompanies {
        flex-direction: column;
        align-items: center;
    }

    #PartnersLeft {
        text-align: center;
        width: 100%;
    }

    .PopularServices {
        height: 7.5vh;

    }

    .PopularServices .Service {
        height: 70%;
        margin-top: 0%;
    }

    .ServiceTitle {
        margin-top: 0;
    }

    #DisplayCompanies {
        margin-top: 10%;
    }

    #PartnersHeader {
        font-size: 1.55em;
    }

    .HomeIntro {
        height: 55svh;
        display: flex;
        flex-direction: column;
    }

    .HomeIntro #lefthome {
        width: 100%;
    }

    .HomeIntro #righthome {
        width: 100%;
        padding-right: 0;
        justify-content: center;

    }
}