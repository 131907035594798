/* AlertModal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal-overlay button {
  cursor: pointer;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 100%;
}

.modal-content {
  text-align: center;
}

.modal-content #ModalButton {
  margin: 5px;
  width: 15vh;
  border-radius: 10px;
  border-style: none;
  padding: 10px;

}

.modal-content #ModalButton:hover {
  transition: .4s;
  color: black !important;
}

/* CustomModal.css */
#custommodal {
  display: flex;
  flex-direction: column;
  padding: 0;
  font-size: 2svh;
}

#custommodal label {
  margin-left: 10%;
  margin-bottom: 1%;
}

#custommodal input {
  width: 80%;
  font-size: 2svh;
  margin-bottom: 2.5%;
  align-self: center;
  padding: 1% 0 1% 1%;
  border-radius: 10px;

}

.modalbuttonswrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  column-gap: 10px;
  padding: 2.5%;
}

#custommodal .modal-button {
  width: 40%;
  border-radius: 10px;
  border: none;
  padding: 1%;
  font-size: 2svh;
}

#modal-header {
  text-align: center;
}

.custommodalRow {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 2.5%;
}

.custommodalRow p,a {
  padding: 0;
  margin: 0;
}

.custommodalRow a {
  color: var(--main);
  font-weight: 600;
  cursor: pointer;
}