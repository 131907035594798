.FreelancerPage {
    background-color: var(--whiteColor);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}

.FreelancerPage .freelancerCategory {
    background-color: var(--whiteColor);
    border-style: solid;
    border-color: #43689c;
    display: flex;
    width: 80%;
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    border-radius: 20px;
    padding-bottom: 2.5%;
    margin-left: 10%;
    padding-left: 2.5%;
}

.FreelancerPage .freelancerCategory .freeCatWrapper {
    width: 30%
}

#bioFreelancer {
    margin: 0%;
    font-size: 1.75svh;
    color: #ababab;
}

.ProfileImg {
    display: flex;
    flex-direction: row;
}

.freeCatWrapper form label select {
    width: 80%;
    height: 5vh;
    font-size: 12px;
    border-radius: 10px;
}

.freeCatWrapper2 label input {
    margin-bottom: 2.5%;
}

.SkillSet {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.SkillSet .SkillWrap {
    background: rgba(52, 174, 212, 0.65);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-left: 2%;
    padding: 0%;
    padding-left: 2%;
    padding-right: 2%;
    border-radius: 5px;
    margin-bottom: 2.5%;
    margin-top: 2.5%;
}

.SkillWrap .SkillText {
    font-size: 12px;
    color: white;
    padding: 0px;
    margin: 5px;
}

.SkillWrap .SkillSpecial {
    font-size: 1.5svh;
    color: black;
    padding: 0px;
    margin: 5px;
}

.FreeProfile {
    background-color: var(--whiteColor);
    width: 47.5%;
    /* width: 100%; */
    border-radius: 2vh;
    margin-bottom: 1.25%;
    margin-top: 1.25%;
    height: 17.5svh;
    display: flex;
    flex-direction: row;
    align-items: center;
    -webkit-user-select: none;
    user-select: none;
}

.FreeProfile:hover {
    scale: 1.025;
    transition: .6s ease-in-out;

}

.FreeProfile #FreeProfileName {
    font-size: 2.25svh;
    font-weight: 600;
    margin: 0;
    padding: 0;
    margin-bottom: 1.25%;
}

.FreeProfile #FreeProfileSpecial {
    color: black;
    margin: 0;
    padding: 0;
    font-size: 1.75svh;
    margin-bottom: 1.25%;
}

.FreeProfile img {
    width: 12.5svh;
    height: 12.5svh;
    border-radius: 1vh;
    margin-top: 1vh;
    margin-left: 3vh;
    margin-right: 1vh;
    padding: 1%;
}

.FreeProfile button {
    margin-bottom: 5%;
    background-color: var(--main);
    border-style: none;
    font-size: 14px;
    font-weight: 500;
    color: white;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 15px;
}

.ViewFreelancer {
    /* margin-left: 5%;
    margin-right: 5%; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* align-items: center; */
    width: 80%;
    gap: 2.5%;
    height: 100svh;
}

#FreelancerFilter {
    position: -webkit-sticky;
    /* For Safari */
    position: sticky;
    top: 10px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    background-color: var(--whiteColor);
    height: 90svh;
    width: 25%;
    margin-left: 1%;
    margin-top: 1%;
    border-radius: 10px;
}

#FreelancerFilter #FreelancerFilterForm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    padding: 5%;
    padding-left: 0;
    padding-right: 0;
}

#FreelancerFilter #FreelancerFilterForm #FreelancerFilterSearch {
    border-radius: 10px;
    padding: 3%;
    padding-left: 0;
    border-width: .5px;
    width: 37.5svh;
    margin-bottom: 2.5%;
    margin-left: 5%;
    font-size: 2.25svh;
}

#FreelancerFilter #FreelancerFilterForm select {
    margin-left: 5%;
    width: 25svh;
    margin-bottom: 2.5%;
    padding: 5%;
    border-radius: 10px;
    border-width: .5px;
}

@media only screen and (max-width: 1024px) {
    .FreeProfile {
        width: 100%;

    }

    .FreeProfile img {
        width: 5svh;
        height: 5svh;
        border-radius: 1vh;
        margin-top: 1vh;
        margin-left: 3vh;
        margin-right: 1vh;
        padding: 1%;
    }

    .FreeProfile #FreeProfileName {
        font-size: 1.25svh;
        font-weight: 600;
        margin: 0;
        padding: 0;
        margin-top: 5%;
        margin-bottom: 2.5%;
    }

    .FreeProfile #FreeProfileSpecial {
        color: black;
        margin: 0;
        padding: 0;
        font-size: 1svh;
        margin-bottom: 2.5%;
    }

    .JobDisplaySection .rightSide {
        padding-left: 0;
    }


}