#AboutWrapper {
    /* padding: 1%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

#AboutWrapper h1,h2,h3,h4 {
    margin-bottom: 1.5%;
}

#VisionText {
    font-weight: 700;
    text-align: center;
}


#SubVisionText {
    font-weight: 700;
    text-align: center;
    font-size: 2.5svh;
}

#VisionSubText {
    font-weight: 300;
    padding-top: .5%;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 2.25svh;
    letter-spacing: .125vh;
    line-height: 2.75svh;
}

.SubVisionText {
    font-weight: 700;
    text-align: left;
    font-size: 2.5svh;
    padding-left: 10%;
}

.SubVisionSubText {
    font-weight: 500;
    padding-top: .5%;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 2.25svh;
    letter-spacing: .125vh;
    text-align: left;
}

#SubVisionSubText {
    font-weight: 500;
    padding-top: .5%;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 2.25svh;
    letter-spacing: .125vh;
}

#FirstBatchAbout {
    /* margin-top: 2.5%; */
    width: 100%;
}

#RowImages {
    display: flex;
    flex-direction: row;
}

#RowImages #Col1Row1 {
    width: 50%;
    height: 50vh;
}

#RowImages #Col2Row1 {
    /* width: 60%; */
    height: 50vh;
}

#RowImagesSecond {
    display: flex;
    flex-direction: row;
    margin-top: -28%;
}

#SectionAbout {
    margin-top: 1%;

}


#CompIntern {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* margin-top: 1.25%; */
    border-style: solid;
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    padding: 2.5%;
    padding-left: 0;
    padding-right: 0;
    border-color: #00000020;
}

#SwitcherImages {
    margin-top: 2%;
    width: 100%;
    height: 50svh;
    background-repeat: no-repeat;
    background-size: cover;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 25%;
    padding-right: 25%;

}

#SwitcherImages img {}

#SwitcherImages h3,
#SwitcherImages h4 {
    color: white;
}


#SwitcherImages h3 {
    font-size: 5svh;
    font-weight: bold;
    font-weight: 600;
    margin: 0;
    padding: 0;
}


#SwitcherImages h4 {
    font-size: 3.25svh;
    font-weight: 200;
    margin: 0;
    padding: 0;
}

#TextDisplay {
    display: flex;
    flex-direction: column;
    align-items: center;
}



#CreateAccountAboutText {
    /* margin-top: 2.5%; */
    width: 50%;
    background-color: var(--main);
    color: white;
    cursor: pointer;
    font-size: 2svh;
    padding: 1%;
    border-radius: 10vh;
    font-weight: 600;
}

#CreateAccountAboutText:hover {
    color: var(--whiteColor);
    background-color: var(--second);
    scale: 1.05;
    transition: .5s ease-in;
    transition-timing-function: linear;
}

#OurMission{
    /* background-color: var(--main); */
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1%;
    padding: 1.25%;
    /* margin-top: 1.25%; */
    /* margin-bottom: 1.25%; */
}
#MissionRegion{
    background-color: var(--whiteColor);
    width: 33.33%;
    padding: 1.25%;
    height: 30svh;
    padding-top: 0;
    border-radius: 20px;
    border-style: solid;
    border-color: #00000070;
    border-width: 0.5px;
    
}
#MissionRegion h4 {
    color: black;
    font-size: 2svh;
    font-weight: 400;
    line-height: 2.5svh;
}
#MissionTitle {
    border-bottom-style: solid;
    padding: 10%;
    border-bottom-color: #00000020;
    padding-bottom: 7.5%;
}

#MissionTitle h3{
    color: var(--main);
    font-size: 2.5svh;
    text-wrap: nowrap;
    font-weight: 600;
}

@media only screen and (max-width: 1024px) {
    #CreateAccountAboutText{
        font-size: .88em;
    }
    #RowImages #Col1Row1 {
        height: 25vh;
    }

    #RowImages #Col2Row1 {
        height: 25vh;
        /* object-fit: contain; */
        object-fit: cover; /* Makes sure the image fills the container */
        object-position: 30% 0; /* Crops 10% from the left */
    }

    #RowImagesSecond #Col1Row2 {
        width: 70%;
        height: 70%;
    }

    #RowImagesSecond {
        margin-top: -7.5%;

    }

    #RowImagesSecond #Col2Row2 {
        width: 35%;
        height: 55%;

    }

    #VisionText {
        font-weight: 700;
        text-align: center;
        font-size: .88em;
    }

    #VisionSubText {
        font-size: .88em;
    }

    Sub#VisionText {
        font-weight: 700;
        text-align: center;
        font-size: .88em;
    }

    #SubVisionSubText {
        font-size: .88em;
    }
    #ImageText{
        font-size: 1em !important;

    }
    #SwitcherImages {}

    #SwitcherImages h3 {
        font-size: 3svh;
        font-weight: bold;

    }


    #SwitcherImages h4 {
        font-size: 2svh;
        font-weight: 200;
        margin: 0;
        padding: 0;
    }

    #SwitcherCompIntern #Switcher {
        font-size: 1.25svh !important;
    }

    #CompIntern {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 1.25%;
        padding: 2.5%;
        padding-left: 0;
        padding-right: 0;
    }

    #OurMission{
        /* background-color: var(--main); */
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    #MissionRegion{
        width: 66.67%;
    }

    #MissionTitle {
        padding: 5%;
        padding-bottom: 2.5%;
    }
    #MissionTitle h3{
        font-size: 1.75svh;
    }
}