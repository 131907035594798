.NotificationsPage {
    display: flex;
    min-height: 100svh;
}

.NotificationsPage .NotificationTitle {
    text-align: center;
}


.NotificationWrapper {
    display: flex;
    flex-direction: row;
}


.NotifyTab {
    width: 90%;
    background-color: var(--whiteColor);
    margin-left: 2.5%;
    /* border-radius: 10px; */
    padding: 1%;
    /* padding-left: 1%; */
    /* padding-right: 2%; */
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    margin-bottom: 2%;
    border-style: solid;
    border-width: 0;
    border-bottom-width: .5px;
    border-color: #00000050;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.NotifyTab:hover{
    border-bottom-width: 1px;
    scale: 1.025;
    transition: .4s ease-in; 
}
.NotifyRight {
    width: 100%;
}

.NotificationPanel {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.UpcomingDates {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 35svw;
    height: 20svh;
    border-style: solid;
    border-radius: 20px;
    padding: 2.5%;
    border-width: .5px;
    cursor: pointer;
    background-color: #00000005;
}

.UpcomingDates:hover {
    scale: 1.025;
    background-color: var(--whiteColor);
    transition: .6s;

}

.lineartab {
    /* background-image: linear-gradient(90deg,#ced9e8,white,white); */
}

#UpcomingNotifications {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* background-color: blue; */
    width: 80%;
    min-height: 20svh;
    max-height: 120svh;
    gap: 2.5%;
    row-gap: 0;
}

.datedesign {
    color: var(--main);
}

#leftNotifications {
    width: 20%;
    padding: 0.5%;
    border-style: solid;
    border-width: 0;
    border-right-width: 0.5px;
    border-color: #00000050;
    margin-top: 2.5%;
    height: 100%;
    text-align: center;
}

#leftNotifications h3 {
    font-size: 1.5svh;
    padding: 5%;
    color: #00000050;
    font-style: italic;
}

#leftNotifications button {
    margin-bottom: 10%;
    padding: 5%;
    font-size: 2svh;
    border-radius: 10px;
    border-style: none;
    font-weight: 600;
}

#leftNotifications button:hover {
}

#hoverOn:hover {
    background-color: var(--main) !important;
    color: white !important;
}

#hoverOff:hover {
    background-color: var(--main) !important;
    color: white !important;

}

@media only screen and (max-width: 1024px) {
    .NotificationsPage {
        display: flex;
        flex-direction: column;
        min-height: 100svh;
    }

    #leftNotifications {
        width: 100%;
        display: flex;
        flex-direction: row !important;
        gap: 2%;

    }

    #leftNotifications button {
        padding: 1%;
        font-size: 1.25svh;
    }
}



@media only screen and (max-width: 500px) {
    .NotificationWrapper {
        flex-direction: column;
    }

    .NotifyRight {
        width: 100%;
    }

    .NotifyRight .UpcomingDates {}
}