.ApplyForasPage {
    background-color: var(--whiteColor);
    /* max-width: 1920px; */
    padding: 2.5% .25%;
    display: flex;
    min-height: 100svh;
}

.statusWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.25%;
}

.ApplyForasPage .ForsaDetails {
    display: flex;
    flex-direction: column;
    width: 75%;
    border-style: solid;
    border-width: 0;
    border-right-width: .1svh;
    justify-content: space-between;
}

.ForsaDetails .leftDetails {
    margin-left: 5%;
    padding: 1%;
    padding-bottom: 0;
}

.ForsaDetails h4 {
    color: black;
}

.ForsaDetails .leftDetails .leftProfile {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ForsaDetails .leftDetails img {
    height: 12.5vh;
    width: 12.5vh;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 1px;
}

.ForsaDetails .leftDetails .ProfileDetails {
    align-self: center;
}

.ForsaDetails .leftDetails .ProfileDetails img {
    height: 2.25vh;
    padding-top: 0;
}

.ForsaDetails .leftDetails .leftProfile .ProfileDetails {
    margin-left: 1.25%;
}

.ForsaDetails .leftDetails .moreDetails h4 {
    font-family: "convergence";
}

.ForsaDetails .leftDetails a {
    color: black;
    text-decoration: none;
}

.ForsaDetails .rightDetails {  
    width: 70%;
    margin-left: 2.5%;
    padding: 1%;

}

.ForsaDetails .rightDetails .jobUpTag {
    display: flex;
    flex-direction: row;
}

.ForsaDetails .rightDetails .jobUpTag .jobTitle {
    width: 85%
}

.ForsaDetails .rightDetails .SecondTag {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    align-content: center;
}

.ForsaDetails .rightDetails .SecondTag .duration {
    margin-right: 5%;
}


.ForsaDetails .rightDetails .SecondTag .timeframe h4 {
    font-family: 'convergence';
    font-weight: 400;
    font-size: 1.5vh;
}

.ForsaDetails .PropertyTags {
    margin-left: 3.25%;
}

.ForsaDetails .rightDetails .ThirdTag {
    margin-bottom: 75%;
}

.ForsaDetails .rightDetails .ThirdTag #AboutThisRole {
    font-weight: 500;
    padding: .5%;
    margin: .5%;
    margin-bottom: 2.5%;
    padding-left: 0;
    font-size: 2.25svh;
}

.ForsaDetails .rightDetails .ThirdTag #ThirdDesc {
    font-weight: 300;
    padding: .5%;
    margin: .5%;
    padding-left: 0;
    font-size: 2.25svh;
    line-height: 3svh;
    text-align: justify;

}

.ForsaDetails .rightDetails .ThirdTag ul {
    margin-top: 0;
}

.ForsaDetails .rightDetails .ThirdTag ul li {}


.ForsaDetails .rightDetails .FourthTag {
    display: flex;
    flex-direction: row;
    margin-top: 2.5%;
}

.ForsaDetails .rightDetails .FourthTag .submittedpeople {
    width: 85%;
}

.ForsaDetails .rightDetails .FourthTag button {
    align-self: center;
    background-color: var(--main);
    border-style: none;
    font-size: 2vh;
    color: white;
    padding-left: 4vh;
    padding-right: 4vh;
    height: 5vh;
}

.SimilarForasSection {
    width: 25%;
}

.SimilarForas {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SimilarForas .tab {
    width: 90%;
    background-color: var(--whiteColor);
    border-width: .1svh;
    border-style: solid;
    border-color: #00000050;
    margin-bottom: 2.5%;
    border-radius: 10px;
    padding: 2.5%;
    min-height: 30svh;
    max-height: 30svh;
}

.tab h4 {
    color: black;
}

.tab .similarjobTitlte {
    width: 100%;
    margin-left: 2.5%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.similarjobTitlte #similarjobTitlteimg {
    width: 50px;
    height: 50px;
    margin-right: 2.5%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 1px;
    object-fit: contain;
}

#SimilarForasTop h4 {
    margin: 0;
    font-size: 2.25svh;
    margin-top: 2.5%;
    font-weight: 600;
}

#SimilarForasTop h5 {
    margin: 0;
    font-size: 1.75svh;
    margin-top: 2.5%;
    color: #00000060;
}

.tab .similarjobDesc {
    margin-left: 2.5%;
    margin-right: 2.5%;
    height: 50%;
    font-size: 2.25svh;
    color: #00000080;
}

.tab .endingSimilarJob {
    display: flex;
    flex-direction: row;
    margin-left: 2.5%;
    margin-right: 2.5%;
    align-items: center;
}

.tab .endingSimilarJob h4 {
    display: flex;
    flex-direction: row;
    margin-left: 2.5%;
    margin-right: 2.5%;
    width: 65%;
}

.tab .endingSimilarJob button {
    padding: 2.5%;
    padding-left: 4%;
    padding-right: 4%;
    border-style: none;
    background-color: var(--main);
    color: white;
    height: 100%;
    font-size: 16px;
    margin-top: 1.5%;
    cursor: pointer;
}

.tab .endingSimilarJob button:hover {
    color: var(--main);
    background-color: var(--whiteColor) !important;
    transition: 0.5s;
}

.file-input-container {
    display: inline-block;
    position: relative;
    width: 5svh;
    height: 5svh;
    border: 2px solid #ccc;
    border-radius: 10px;
    text-align: center;
    vertical-align: middle;
    line-height: 6svh;
    /* Center the text/icon vertically */
    cursor: pointer;
    background-color: #f9f9f9;
}

.file-input-container:hover {
    background-color: #e9e9e9;
}

.file-input-container input[type='file'] {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.file-input-icon {
    font-size: 2.5svh;
    color: #555;
}

.tab .endingSimilarJob button:hover {
    background-color: #0F4789;

}

.backdrop {
    background-color: #363636;
}

#displayApplyTag {
    display: flex;
    gap: 2.5%;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    width: 100%;
    /* margin-left: 15%;     */
}

#Compensation{
    text-align: center;
    font-size: 2.25svh;
    border-style: solid;
    width: 40%;
    border-radius: 20px;
    padding: 1%;
    border-width: .5px;
    margin-left: 50%;
}

#Compensation h4{
    padding: 0;
    margin: 0;
}

#ApplyForasTag {
    background-color: #d9d9d990;
    font-size: 1svh;
    padding: 1.75%;
    text-align: center;
    border-radius: 10px;
}

#ApplyForasTag h3 {
    color: black;
    font-weight: 400;
    font-style: italic;
    text-wrap: nowrap;
    font-size: 1.5svh;
}

#ApplyNowButton {
    border-radius: 10px;
    height: 100%;
    border-style: none;
    padding-top: 1.25vh;
    padding-bottom: 1.25vh;
    padding-left: 3vh;
    padding-right: 3vh;
    font-weight: 400;
    font-size: 1.75svh;
    font-style: italic;
    color: white;
    background-color: var(--main);
    cursor: pointer;
}

#ApplyNowButton:hover {
    background-color: var(--second);
    color: var(--main);
    font-weight: 600;
    transition: .4s;
}

#jobtitleNew {
    font-size: 20px;
}
#jobPayment{
    font-size: 1.75svh;
    color: var(--main);
}
#SimilarJobApply {
    text-wrap: nowrap;
    border-radius: 10px;
    padding: 3.5%;
    padding-left: 5%;
    padding-right: 5%;
    font-size: .88em;
}

#ViewInvitationsButton {
    width: 25%;
    font-size: 2.25svh;
    border-radius: 10px;
}

#popUpModal {
    width: 50%;
    height: 50%;
    background-color: var(--second);
    position: fixed;
    top: 25%;
    left: 25%;
    border-style: solid;
    border-width: .5px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#userRow {
    display: flex;
    border-bottom-style: solid;
    flex-direction: row;
    gap: 5%;
    padding: 1.25%;
    padding-top: 2.5%;
    padding-left: 5%;
    padding-right: 5%;
    border-bottom-width: .5px;
    justify-content: space-between;
    align-items: center;
}

#LinkuserRow {
    text-decoration: none;
    font-size: 2.25svh;
}

#buttonModal {
    background-color: #00000020;
    padding: 1%;
    font-size: 2.25svh;
    border-style: none;
    border-radius: 10px;
    font-weight: 500;
    width: 5svw;
    cursor: pointer;
    justify-self: flex-end;
}

#buttonModal:hover {
    background-color: #00000050 !important;
    color: black !important;
}

#userRow h4 {
    margin: 0;
}

@media only screen and (max-width: 1024px) {
    .SimilarForas .tab {
        min-height: 20svh;
        max-height: 20svh;
    }
    #ApplyForasTag{}
    .ApplyForasPage {
        flex-direction: column;
    }
    .ForsaDetails .rightDetails{
        width: 90%;
    }
    .ApplyForasPage h3 {
        font-size: .99em;
    }

    .ApplyForasPage h4 {
        font-size: .88em;
    }
    #ApplyNowButton  {
        font-size: .88em;
    }

    .ApplyForasPage .ForsaDetails {
        width: 100%;
        border-right: 0;
    }

    .SimilarForasSection {
        width: 100%;
        font-size: .88em;
    }

    .SimilarForas {
        flex-direction: row;
        gap: 3%;
    }
}

@media only screen and (max-width: 1024px) {
    .tab
    .similarjobTitlte #similarjobTitlteimg {
        width: 3.66svh;
        height: 3.66svh;
        
    }
    #jobtitleNew {
        font-size: 1.11em;
    }
    #SimilarForasTop h4 {
        margin: 0;
        font-size: 1.75svh;
        margin-top: 2.5%;
        font-weight: 600;
    }
    
    #SimilarForasTop h5 {
        margin: 0;
        font-size: 1svh;
        margin-top: 5%;
        color: #00000060;
    }
    #jobPayment{
        font-size: 1.75svh;
    }
    #SimilarJobApply{
        font-size: 1.75svh;
    }
}
