

#GetCreditsInputWrapper {
    display: flex;
    justify-content: center;
}
#GetCreditsInputWrapper input{
    border-radius: 0;
    margin-top: 5%;
    margin-bottom: 5%;
}
#GetCreditsInput {
    width: 20svw;
    padding: 1vh;
    border-radius: 10px;
    margin-bottom: 5%;
    border-width: 1px;
}

#PageWrapper {
    height: 75svh;
    width: 33%;
    border-style: solid;
    border-width: 0.5px;
    border-radius: 20px;
    background-color: var(--whiteColor);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 2.5%;
}

#PageWrapperButton {
    background-color: #00000020;
    border-style: none;
    padding: 2%;
    font-size: 1.25svh;
    font-style: italic;
    border-radius: 5px;
    align-self:flex-start;
    margin-left: 7.5%;
    cursor: pointer;
    margin-top: 2.5%;
}
#PageWrapperButton:hover {
    background-color: #00000030 !important;
    color: black !important;
}

#AddCreditsButton {
    width: 50%;
    margin-top: 25px;
    border-radius: 10px;
    background-color: var(--main);
    cursor: pointer;
    border-width: 0;
    font-size: 1.5svh;
    padding: 1.25%;
    padding-top: 1.75%;
    padding-bottom: 1.75%;
    font-weight: 600;
    color: white;
}

.table-container {
    width: 90%;
    font-size: 1.5svh;
    line-height: 2svh;
}

.receipt-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #f9f9f9;
    color: #333;
    font-family: Courier, monospace;
    border: 1px solid #ccc;
}

.receipt-table th,
.receipt-table td {
    border-bottom: 1px solid #ccc;
    padding: 8px;
    text-align: center; /* Center text in all cells */
}

.receipt-table th {
    border-bottom: 1px solid #333; /* Thicker border for header row */
}

.separator {
    border-bottom: 1px solid #333; /* Line separating columns */
}

.value {
    text-align: right; /* Align values to the right */
}

#Addprofilebutton:hover{
    background-color: var(--second) !important;
    color: white !important;
}