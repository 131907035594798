#ImageBatch .row {
    display: flex;
    flex-direction: row;
}

#ImageBatch .image1 {
    width: 70%;
    height: 70%;
    margin-top: 0;
}

#ImageBatch .image2 {
    width: 50%;
    margin-left: -10%;
    margin-right: 15%;
}

#ImageBatch .image3 {
    width: 90%;
    height: 90%;
    margin-top: -10%;
}

#ImageBatch .image4 {
    width: 45.5%;
    height: 45.5%;
    margin-left: -35%;
    margin-top: -10%;
}


@media only screen and (max-width: 1024px) {
    #ImageBatch .image1 {
        width: 100%;
        height: 100%;
        margin-top: -5%;
    }

    #ImageBatch .image2 {
        width: 50%;
        margin-left: -10%;
        margin-right: 15%;
        margin-top: 0;

    }

    #ImageBatch .image3 {
        width: 66.66%;
        height: 66.66%;
        margin-top: -22.5%;
    }

    #ImageBatch .image4 {
        width: 33.67%;
        height: 33.67%;
        margin-left: 0;
        margin-top: -22.5%;
    }


}