@import url('https://fonts.googleapis.com/css2?family=Days+One&display=swap');

#LogoFont {
  font-family: 'Days One', sans-serif !important;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0px;
}

.nav {
  height: 60px;
  width: 100%;
  background-color: var(--whiteColor);
  position: relative;
  margin-bottom: 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  /* max-width: 1920px; */
  margin-left: auto;
  /* Automatically adjust left margin */
  margin-right: auto;
  /* Automatically adjust right margin */
  text-wrap: nowrap;
}

.nav .navwrapper {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
}

.nav .navwrapper a {
  font-size: .99em;
  letter-spacing: -.5px;
  -webkit-user-select: none;
  user-select: none;
  font-weight: 500;
  /* font-style: italic; */
}

.ResponsiveMenu {
  display: none;
}

.nav .DropMenu {
  background-color: #52747f;
  height: 600px;
  width: 60%;
  position: absolute;
  top: 60px;
  left: 40%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-top: 20px;
  z-index: 9999;
}

.nav .DropMenu .DropMenuWrapper {
  display: flex;
  flex-direction: column;
}

.DropMenu .DropMenuWrapper #LoginSmallWrapper {
  background-color: var(--main);
  color: white;
  width: 50%;
  align-self: center;
  text-align: center;
  padding: 5px;
  border-radius: 10px;
}

.DropMenu .DropMenuWrapper #LoginSmallWrapper:hover {
}
.nav .DropMenu a {
  text-decoration: none;
  color: white;
  /* border-style: solid; */
  border-top: 0px;
  border-left: 0;
  border-right: 0;
  border-bottom-width: 1px;
  margin-bottom: 30px;
  font-size: 14px;
  margin-left: 5%;
  font-weight: 300;
}

.nav a {
  cursor: pointer;
}

.nav .NavbarEnvelope {
  padding: 1.25%;
  font-size: 16px;
  position: fixed;
  left: 5%;
  top: 40%;
  border-radius: 25%;
  color: white;
  z-index: 9999;
  background: rgba(52, 174, 212, 0.55);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.1px);
  -webkit-backdrop-filter: blur(9.1px);
}

.floatinglinks {}

.nav .NavbarEnvelope:hover {
  background-color: var(--whiteColor);
  color: black;
}

.nav .RegBellEnvelope {
  padding: 1.25%;
  font-size: 16px;
  position: fixed;
  left: 5%;
  top: 50%;
  border-radius: 25%;
  color: white;
  z-index: 9999;
  background: rgba(52, 174, 212, 0.55);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.1px);
  -webkit-backdrop-filter: blur(9.1px);
}

.nav .RegBellEnvelope:hover {
  background-color: var(--whiteColor);
  color: black;
}

.floatinglinks .blackIcon {
  background-color: black;
  border-radius: 50%;
  color: black;
}
@media only screen and (min-width: 1920px) {
  .nav .navwrapper a {
    font-size: 1.25svh;
  }
  #SignUpText {
    font-size: 1.25svh !important;
  }
}

@media only screen and (max-width: 1425px) {
  .nav .navwrapper a{
    font-size: 1.11em;
  }

}

@media only screen and (max-width: 1024px) {
  .nav {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
    bottom: 60px;
    box-shadow: rgba(0, 0, 0, 1) 0px .5px .5px;
  }

  .nav .navwrapper {
    display: none;
  }
  .nav .navwrapper a{
  }


  .ResponsiveMenu {
    display: block;
  }

  .ImageContainer {
    width: 90%;
  }

}



.nav .navwrapper .nav-logo {
  height: 100%;
  padding-left: 5%;
  width: 25%;
}

.LetsCash {
  font-size: 8px;
  margin-left: 1%;
  width: 5%;
  display: none;
  color: var(--main);
  font-weight: 700;
}

.LetsCash span {
  color: green;
}
.DropMenu #Logout{
    width: 30%;
    margin: 0 auto; /* alignSelf: 'center' equivalent */
    padding: 2.5%;
    border-radius: 10px;
    border: none;
    background-color: var(--main);
    color: white;
    font-weight: 600;
    font-size: 1.33svh;
}
.nav .navwrapper .nav-logo:hover+.LetsCash {
  display: block;
  position: absolute;
  left: 200px;
}

.navwrapper .navlinks {
  width: 50%;
  display: flex;
  justify-content: center;
  align-self: center;
}

.navwrapper .navlinks a {
  margin-right: 5%;

  text-decoration: none;
  color: var(--main);
}

.navwrapper .navlinks a:hover {
  color: black;
  text-decoration: underline;
}


.navwrapper .navlinks2 {
  width: 25%;
  display: flex;
}

.navwrapper .navlinks2 a {
  padding-left: 20%;
  text-decoration: none;
  color: var(--main);
}

.navwrapper .navlinks2 a:hover {
  color: black;
}

.navwrapper .navlinks2 a:active {
  color: black;
  animation: fadeIn .2s ease-in-out;
}

.navwrapper .navlinks2 button {
  margin-right: 5%;
  cursor: pointer;
  width: 15vh;
  font-size: 14px;
  background-color: var(--main);
  color: white;
  border-style: none;
  padding-top: .5vh;
  padding-bottom: .5vh;
  border-radius: 0.5vh;
  /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

#NotLogged {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-style: solid;
  height: 4vh;
  border-width: 0;
  border-left-width: 1px;
  margin-left: 12.5%;
}

#NotLogged h3 {
  text-wrap: nowrap;
}

#NotLogged #loginText {
  color: black;
  /* font-style: italic; */
}

#NotLogged #loginText:hover {

}

#NotLogged #SignUpText {
  font-size: 1.75svh;
}

#NotLogged #SignUpTextWrapper {
  background-color: var(--main);
  color: var(--whiteColor);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  margin-left: 10%;
  padding: 1.5%;
  border-radius: 20px;
  padding-left: 7.5%;
  padding-right: 7.5%;
  /* font-style: italic; */
}

#NotLogged #SignUpTextWrapper:hover {
  background-color: var(--second);
  transition: .4s;

}

.NotifyWrap {
  padding: 1%;
  padding-left: 3%;
  padding-right: 3%;
  border-style: solid;
  /* border-top: 0;
      border-left: 0;
      border-right: 0; */
  border-width: 4px;
  /* Assuming you meant to specify 0.25 pixels */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
  margin-bottom: 2%;
  width: 90%;
  margin-left: 5%;
  border-radius: 20px;
  cursor: pointer;
}
.NotifyWrap:hover{
  border-bottom-width: 1px;
}

.navwrapper .navlinks2 button:hover {
  background-color: var(--whiteColor);
  color: var(--main);
}

.navwrapper .navlinks2 button.red {
  background-color: red;
}

.navwrapper .navlinks2 button.red:hover {
  background-color: var(--whiteColor);
  color: red;
}

.NotifyPanel a {
  color: var(--main);
  font-size: 10px;
  text-decoration: none;
  align-self: end;
  margin-left: 10%;
  padding-bottom: 1%;
}

.NotifyPanel a:hover {
  color: #153E78;

}

.MenuDrop {
  height: 150px;
  width: 300px;
  position: absolute;
  top: 60px;
  left: 50px;
  background-color: var(--whiteColor);
}

.MenuDrop a {
  text-decoration: none;
  color: black;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: slideDown 0.3s ease-in-out;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu li {
  padding: 10px;
}

.dropdown-menu a {
  color: #333;
  text-decoration: none;
}

.dropdown-menu a:hover {
  color: #000;
  font-weight: bold;
}

.logoutspace {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  cursor: pointer;
  /* padding-left: 10%; */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeIn {
  animation: fadeIn .2s ease-in-out;
  /* From https://css.glass */
  /* From https://css.glass */
  background: rgba(52, 174, 212, 0.55);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.1px);
  -webkit-backdrop-filter: blur(9.1px);
}

.NotifyWrap {
  border-radius: 0;
  border-width: 0;
  border-bottom-width: .5px;
}

.NotifyWrap h4 {
  text-wrap: wrap;
  color: black;
}

.NotifyWrap h5 {
  text-wrap: wrap;
}

#DateNotification {
  margin: 0;
  padding: 0;
  font-size: 1svh;
  font-weight: 300;
  color: #00000080;
  font-style: italic;
}

#BottomNotifyWrap {
  display: flex;
  justify-content: space-between;
  margin-top: 2.5%;
}



/* CHAT PAGE CSS */
#ChatNavbarWrapper {
  background-color: var(--main);

}

#ChatNavbarWrapper:hover {
  /* background-color: var(--whiteColor); */
}

#ChatNavbarText {
  margin: 0;
  padding: 0;
  margin-left: 2.5%;
  font-weight: 600;
  font-size: 1.75svh;
  text-wrap: nowrap;
}

#ReturnNavbar {
  color: white;
}

#ReturnNavbar #ChatNavbarText:hover {}