#DisplayJobEmployer {
    display: flex;
    flex-direction: column;
    padding: 1%;
}

.rightside .DisplayJobEmployer {
    background-color: var(--whiteColor);
    width: 22.5%;
    padding: 2%;
    padding-top: 1%;
    margin-bottom: 1%;
    border-style: solid;
    border-width: .5px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.rightside .DisplayJobEmployer h4 {
    font-weight: 400;
    font-size: 1.85svh;
    color: black;
}

#DisplayJobEmployerTitle {
    font-size: 1.85svh;
    padding: 0;
    text-align: center;
}

#DisplayJobEmployerDesc {
    font-weight: 300;
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 0;
    margin-right: 0;
    font-size: 1.5svh;
}

#genActiveEmployer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 2.5%;
    margin-left: 5%;
}

#genActiveJobs {
    margin-left: 5%;
}

#WalletCredits {
    display: flex;
    align-items: center;
    margin-right: 2.5%;
    border-color: #00000050;
    border-style: solid;
    border-width: .5px;
    padding: 1.25%;
    padding-right: 2.5%;
    padding-left: 2.5%;
    border-radius: 20px;
    height: 50%;
    font-style: italic;
}

#WalletCredits h3 {
    font-size: 1.5svh;
    text-wrap: nowrap;
}

#EmployerProfileSwitcher {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-left: 2.5%;
    padding: 1%;
    padding-left: 2.5%;
    flex-wrap: wrap;
}

#HideButton {
    width: 2.5%;
    font-size: 1.5svh;
}

#HideButton:hover {
    background-color: var(--main)80 !important;
}

#HideButton2 {
    width: 2.5%;
    font-size: 1.5svh;
}

#HideButton2:hover {
    background-color: var(--whiteColor) !important;
}

#ButtonEmployerProfile:hover {
    background-color: var(--whiteColor);
    color: var(--main);
    transition: .6s;
}

#SwitcherButtons:hover {
    background-color: var(--main) !important;
    color: white !important;
    transition: .3s !important;
}

#SwitcherButtonsActive:hover {
    background-color: var(--main) !important;
    color: white !important;
}

#ViewApplicants {
    width: 20%;
}

@media only screen and (max-width: 1024px) {
    #AddCreditsButton {
        display: none;
    }

    .rightside .DisplayJobEmployer {
        background-color: var(--whiteColor);
        width: 47.5%;
        padding: 2%;
        padding-top: 1%;
        margin-bottom: 5%;
        border-style: solid;
        border-width: .5px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #EmployerProfileSwitcher #SwitcherButtons {
        font-size: 10px !important;
        width: 16.67% !important;

    }

    #DisplayJobEmployerTitle {
        font-size: 1.5svh;
        padding: 2.5%;
    }

    .rightside .DisplayJobEmployer h4 {
        font-weight: 400;
        font-size: 1.5svh;
        color: black;
    }

    #ButtonEmployerProfile {
        font-size: 1.25svh;
    }

    #genActiveEmployer {
        gap: 2.5%;
        margin-left: 0;
        justify-content: center;
    }

    #genActiveJobs {
        margin-left: 0;
        text-align: center;
        padding: 2.5%;
        font-size: 1.5svh;
    }

    .ProfilePage .ProfileDesc h2 {
        font-size: 1.5svh;
    }

    .ProfilePage .ProfileDesc h3 {
        font-size: .88svh;
    }

    #ViewApplicants {
        width: 40%;
    }

    #DisplayJobEmployer button {
        width: 30svw;
        font-size: 12px;
    }

    #DisplayJobEmployer {
        height: 17.5svh;
    }
}


@media only screen and (max-width: 768px) {
    #AddCreditsButton {
        display: none;
    }

    .rightside .DisplayJobEmployer {
        background-color: var(--whiteColor);
        width: 75%;
        padding: 2%;
        padding-top: 1%;
        margin-bottom: 5%;
        border-style: solid;
        border-width: .5px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}