#homeJobsTab {
    border-style: solid;
    border-width: .5px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#homeJobsTab {
    padding: 1%;
    min-width: 33svw;
    max-width: 33svw;
    min-height: 22.5svh;
    /* max-height: 22.5svh; */
    flex-shrink: 0;
    box-sizing: border-box;
    /* text-wrap: wrap; */
}


#homeJobsTab h4 {
    padding: 0;
    margin: 0;
}

#homeJobsTitle {
    display: flex;
    align-items: center;
    gap: 2.5%;
    margin-bottom: 2.5%;
}

#homeJobsTitleImg {
    width: 5svh;
    height: 5svh;
    border-radius: 50%;
    object-fit: contain;
}

#homeJobsTop {}

#appliedbanner{
    display: flex;
    align-items: center;
    padding: 1.25%;
}

#appliedbanner button{
    width: 100%;}
#homeJobsTitleText {
    text-transform: capitalize; /* Capitalizes the first letter */
    font-size: 1em;
    font-weight: 600;
}

#homeJobsOwnerName {
    font-size: .77em;
    font-weight: 300;
    text-transform: capitalize; /* Capitalizes the first letter */

}

#homeJobsDescription {
    margin-bottom: 2.5%;
}

#homeJobsDescriptionText {
    font-weight: 400;
    font-size: .99em;
    line-height: 1.33em;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to limit */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

#homeJobsEnding {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#homeJobsPayment {
    color: var(--main);
}

#homeJobsApplyButton {
    border-style: none;
    padding: 2.5%;
    background-color: var(--main);
    color: white;
    border-radius: 10px;
    text-wrap: nowrap;
}

#homeJobsApplyButton:hover {
    background-color: var(--second) !important;
    color: white !important;
}

@media only screen and (max-width: 1024px) {
    #homeJobsApplyButton {
        padding: 1.75%;
    }

    #homeJobsTab {
        padding: 2.5%;
        min-width: 62.5%;
        max-width: 62.5%;
        min-height: 15svh;
        /* max-height: 15svh; */
        font-size: .75em;
    }

    #homeJobsTitleImg {
        width: 3.667svh;
        height: 3.667svh;
    }

    #homeJobsTitleText {
        font-size: 1.25svh;
    }

    #homeJobsTop {
        display: flex;
        flex-direction: column;
    }

    #homeJobsTitle {
        gap: 2.5%;
        padding-bottom: .25%;
        align-items: end;
    }

}

@media only screen and (max-width: 768px) {
    #homeJobsApplyButton {
        padding: 1.25%;
        font-size: 12px;
    }

    #homeJobsTab {
        padding: 2.5%;
        min-width: 62.5%;
        max-width: 62.5%;
        min-height: 12.5svh;
        /* max-height: 12.5svh; */
        font-size: .75em;
    }

    #homeJobsDescription {
        margin-bottom: 1.25%;
    }

    #homeJobsDescriptionText {
        font-size: .88em;
    }


    #PartnersText {
        font-size: .88em;
        text-align: left;
    }

    #homeJobsEnding {}
}