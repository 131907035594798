.LoginPage {
  background-color: #f9f9f9;
  /* max-width: 1920px; */
  display: flex;
  justify-content: center;
}

.LoginWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: var(--whiteColor);
  border-radius: 5px;

}

.LoginWrapper .LeftSide {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.LoginWrapper .LeftSide img {}

.LoginWrapper .RightSide {
  width: 50%;
  display: flex;
  flex-direction: column;

}
.emailError{
  width: 25svw;
  align-self: center;
  display: flex;
  justify-content: flex-end;
  margin-top: -2.5%;

}
.emailErrortext {
  padding: 0;
  margin: 0;
  font-size: 10px;
}
.LoginWrapper .RightSide input {
  font-size: 12px;
  text-decoration: none;

}

.LoginWrapper .RightSide a {
  font-size: 12px;
  text-decoration: none;
}

.LoginWrapper .RightSide a:hover {
  color: var(--main);
  transition: .4s;

}

.LoginWrapper .RightSide input {
  border-style: solid !important;
  border-width: .5px;
}

.LoginWrapper .RightSide input[type=email] {
  font-size: 14px;
  padding: 4px;
  padding-left: 10px;
  border-radius: 10px;
  border-style: none;
}

.SignUpForm{
  margin-top: 2.5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.SignUpForm input {
  width: 75% !important;
  border-radius: 0 !important;
  padding: 2.5% !important;
  align-self: center;
}

.SignUpForm h3 {
  color: black;
  font-size: 1.75svh;
  font-weight: 500;
  margin-top: 2%;
  margin-bottom: 2.5%;
  margin-left: 12.5%;
}

.pleasefillout{
  color: black;
  font-size: 2svh;
  font-weight: 500;
  margin-top: 2.5%;
  margin-bottom: 0;
  text-align: center;
}
.notEmployerform {
  display: flex;
  justify-content: center;
  margin-bottom: 2.5%;
  width: 75%;
  align-self: center;
}
.notEmployerform input{
  width: 12svw !important;
}

.byCreating{
  margin-top: 2.5%;
}
.LoginWrapper #NameWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.LoginWrapper .RightSide input[type=text] {
  font-size: 14px;
  padding: 4px;
  padding-left: 10px;
  border-style: none;

}

.LoginWrapper .RightSide #user {
  font-size: 2svh;
  border-style: none;

}

.LoginWrapper .RightSide input[type=password] {
  margin-bottom: .5%;
  font-size: 2svh;


  border-style: none;
}

.LoginWrapper .RightSide input[type=submit] {
  
  padding: 2.5%;
  width: 20svw;
  border-style: none;
  background-color: var(--main);
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  font-size: 2svh;
}

.LoginWrapper .RightSide input[type=submit]:hover {
  background-color: var(--second);
  color: var(--whiteColor);
  transition: .4s;
}

.LoginWrapper .LoginCreateAccount {
  font-size: 12px;
  margin-top: 5%;
}

.LoginWrapper .AlreadyAccount {
  margin-top: 10px;
  font-size: 12px;
  position: absolute;
  top: 40px;

}

.LoginWrapper .LoginCreateAccount a {
  text-decoration: none;
  color: red;
  font-size: 2svh;
}

#ChooseIndustryDropDown select{
  width: 75% !important;
  margin-bottom: 2.5% !important;
  border-radius: 5px !important;
  padding: 2.5% !important;
  border-width: .5px;
  margin-left: 12.5%;
}
.LoginWrapper .LoginCreateAccount a:hover {
  color: var(--main);
  transition: .4s;
}

.SignUpButtons button {
  background-color: var(--whiteColor);
  border-style: none;
  color: var(--main);
  font-weight: 400;
  border-radius: 10px;
}

.SignUpButtons {
  width: 50svh;
}

.SignUpButtons button:hover {
  background-color: var(--main)60;
}

.SignUpButtons button.active {
  background-color: var(--main);
  color: white;
}
.SignUpButtons h5{
}
#LoginText {
  color: black;
  margin: 0;
  margin-bottom: 2.5%;
  text-align: left;
  font-weight: 500;
  font-size: 2svh;
}

.big-button {
  width: 100%;
  padding: 5%;
  margin: 10px 0;
  text-align: center;
  background-color: var(--whiteColor);
  border: 1px solid #ddd;
  cursor: pointer;
  border-radius: 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
  -webkit-user-select: none;
  user-select: none;
}

#CreateAccountText {
  font-size: 3.5svh;
  text-align: left;
  font-weight: 700;
}

.big-button h3 {
  font-size: 2.25svh;
  font-weight: 700;
}

.big-button h5 {
  font-size: 1.75svh;
  padding: 0;
  margin: 0;
  padding-top: 2.5%;
  text-wrap: wrap;
}

.big-button.active {
  background-color: var(--whiteColor);
  border-color: var(--main);
  color: var(--main);
  scale: 1.025;
  transition: .2s ease-in;
}

#handleNextButton {
  background-color: var(--main);
  border-style: none;
  width: 35svh;
  padding: 2.5%;
  border-radius: 10px;
  font-weight: 600;
  color: white;
  margin-top: 5%;
}

#handleNextButton:hover{
  background-color: var(--second) !important;
  color: var(--whiteColor) !important;
}


.forgetPass {
  width: 20vw;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2.5%;
}

@media only screen and (max-width: 768px) {
  .LoginPage .LoginWrapper .LeftSide {
    display: none;
  }

  .LoginWrapper {
    width: 95%;
  }
}

@media only screen and (max-width: 1024px) {
  .LoginPage .LoginWrapper .LeftSide {
    width: 30%;
  }

  .LoginWrapper .LeftSide img {
    width: 100%;
  }

  .LoginWrapper {
    flex-wrap: wrap-reverse;
  }

  .LoginWrapper .RightSide {
    width: 100%;
    margin-top: 10%;

  }

}