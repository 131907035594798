.jobBanner {
    width: 47.5%;
    border-width: .5px; 
    border-style: solid; 
    border-color: #00000030; 
    padding: 2.5vh 2.5vh 2.5vh 0;
    margin-bottom: 2.5%;
    background-color: var(--whiteColor);
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    user-select: none;
    -webkit-user-select: none;
}
.jobBanner:hover{
    scale: 1.025;
    transition: .6s ease-out;
}
.jobBanner h4,
h5 {
    color: black;
    text-wrap: nowrap;
    margin: 0;
}
.jobBanner #TopDetails h4,h5{
    margin:2%;
    margin-left: 0;
}

.filterCategory .CategoryRadio {
    margin-top: 2.5%;
}

.filterCategory .CategoryRadio input {
    margin-bottom: 2.5%;
}

#Switcher {
    width: 50%;
    padding: 2.5%;
    font-size: 2svh;
}

#TopArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* background-color: green; */
    /* justify-content: space-between; */
    margin-bottom: 4.25%;
    margin-left: 2.5vh;/*After Job tag added*/
}
.imgWrapper{
    height: 10svh;
    margin-right: 2.5%;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 3px;
    border-radius: 10%;
}

#TopArea img {
    width: 10svh;
    height: 10svh;
    border-radius: 10%;
    object-fit: contain;
}

#TopDetailText1 {
    font-size: 2svh;
    color: #00000090;
    font-weight: 300;
}

#TopDetailText2 {
    font-size: 2svh;
}

#TopDetailsTitle{
    font-size: 2svh;
    font-weight: 700;
    margin-top: 5% !important;
}

#TopDetails {
    display: flex;
    flex-direction: column;
    width: 30%;
}

#JobTagsBanner {
    display: flex;
    /* margin-top: 3.5%; */
}

#JobTagSingle {
    margin-right: 3.33%;
    background-color: #d9d9d990;
    height: 2.75svh !important;
    min-width: 15svh;
    padding-left: 7.5%;
    padding-right: 7.5%;
    border-radius: 40px;
    height: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#JobTagSingle h5 {
    color: black;
    font-weight: 500;
    font-size: 1.5svh;
    margin: 0;
    padding: 0;
}

#BottomArea {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 2.5vh;/*After Job tag added*/

}

#HeartArea{
   display: flex;
   justify-content: space-between;
   margin: 0;
   padding: 0;
   margin-bottom: 2.5%;
   /* background-color: green; */
}

#BoxType {
    border: solid .5px;
    border-left: 0;
    display: flex;
    align-items: center;
    padding-left: 2.5%;
    padding-right: 2.5%;
    font-size: 12px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

@media only screen and (max-width: 1024px) {
    .jobBanner {
        /* padding-left: 1.25svh; */
        padding-right: 1.25svh;
    }

    #JobTagSingle {
        margin-right: 2.5%;
        background-color: #d9d9d990;
        padding: 0;
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 7.5svh;

    }
    .jobBanner h3 {
        font-size: 11px;
    }

    .jobBanner h4 {
        font-size: 1.11svh;
    }

    #JobTagSingle h5 {
        font-size: .75svh;
    }
    #jobDurationText{
        font-size: 1svh;
    }
}

@media only screen and (max-width: 768px) {
    .jobBanner {
        width: 100%;
        /* padding-left: 1.25vh; */
        padding-right: 1.25vh;
        margin-bottom: 2.5%;
        background-color: var(--whiteColor);
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 10px;
        cursor: pointer;
        height: 21.25svh;
    }
    #JobTagSingle {
        margin-right: 5%;
        background-color: #d9d9d990;
        padding: 0;
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 9.5svh;
    }
    .jobBanner h3 {
        font-size: 11px;
    }

    .jobBanner h4 {
        font-size: 1.5svh;
    }

    .jobBanner h5 {
        font-size: 1.25svh;

    }

    .rightJobTitle button {
        border-radius: 10px;
    }

    #JobTagSingle h5 {
        font-size: 1svh;
    }
}