.ContactView{
    /* margin-top: 1.25%; */
    padding: 5%;
    max-width: 1920px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100svh;
    background-color: #00000005;
}

.ContactView .ContactTitle{
    text-align: center;
    font-size: 1.66em;
    font-weight: 600;
    margin-top: 5%;
}
#ContactText{
    font-weight: 300;
    margin-top: 0;
    font-size: .99em;
    line-height: 1.66em;
}
.ContactView .ContactBox{
    box-shadow: rgba(0, 0, 0, 0.2) 4px 4px 16px;
    background: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2.5%;
}
#ContactForm{
    width: 62.5%;
}
.ContactView .ContactBox .ContactRow{
    display: flex;
    flex-direction: column;
    
}
#QuickLinks{
    color: blue;
    font-size:2svh;
}
#QuickLinks:hover{
    font-size: 2svh;
    transition: .6s;
}
.ContactView .ContactBox input{
    width: 75vh;
    padding: 1vh;
    border-radius: 5px;
    margin-bottom: 5%;
    border-width: 1px;
    font-size: 2svh;
}

.ContactRow h7{
    font-size: 12px;
    color: #ababab;
    margin-bottom: 1%;
}

.ContactView .ContactBox textarea{
    width: 75vh;
    padding: 1vh;
    border-radius: 5px;
    margin-bottom: 5%;
    border-width: 1px;
    height: 25vh;
    font-size: 2svh;
}


.ContactView .ContactBox button{
    padding: 1vh;
    padding-left: 5vh; padding-right: 5vh;
    border-radius: 10px;
    margin-bottom: 5%;
    border-width: 1px;
    border-width: 0;
    background-color: var(--main);
    color: white;
    cursor: pointer;
}


.ContactView .ContactBox button:hover{
    color: var(--main);
    background-color: var(--whiteColor);
    transition: .3s;
}

@media only screen and (max-width: 1200px) {
    .ContactView .ContactBox input{
        width: 40vh;
        align-self: center;
    }
    .ContactView .ContactBox textarea{
        width: 40vh;
        align-self: center;
    }
    .ContactView{
       overflow-x: hidden;
    }
    .ContactRow h7{
        font-size: 6px;
        text-align: center;
    }
    #ContactForm{
        width: 100%;
    }
    #MoreContact h4{
        font-size: 1svh !important;
    }
    #MoreContact a{
        font-size: 1svh !important;
    }
}