#SignUpComplete {
  height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  background-color: #f0f4f8;
}

#SignUpComplete .icon-wrapper {
  background-color: var(--whiteColor);
  padding: 20px;
  border-radius: 50%;
  margin-bottom: 20px;
}

#SignUpComplete .email-icon {
  font-size: 50px;
  color: var(--main);
}

#SignUpComplete h3 {
  font-size: 1.8em;
  margin-bottom: 10px;
  color: #333;
}

#SignUpComplete h4 {
  font-size: 1.2em;
  margin-bottom: 20px;
  color: #555;
}

#SignUpComplete p {
  font-size: 1em;
  color: #666;
}

#SignUpComplete a {
  color: #1a73e8;
  text-decoration: none;
}

#SignUpComplete a:hover {
  text-decoration: underline;
}

#SignUpComplete a {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1em;
  color: white;
  background-color: var(--main);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

#SignUpComplete a:hover {
  background-color: var(--main)50;
  transition: .6s;
  text-decoration: none;
}
