.ProfilePage {
  padding-top: 2.5%;
  min-height: 125svh !important;
  display: flex;
  flex-direction: column;
}

.ProfilePage .Experience {
  background-color: var(--whiteColor);
  padding-left: 2.5%;
  padding-right: 2.5%;
  border-style: solid;
  border-width: 0;
}

.ProfilePage .BannerSection .BackgroundProfile {
  height: 100%;
  width: 100%;
}

.ProfilePage .ProfileTag {
  margin-left: 5%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ProfilePage .ProfileImg {
  width: 12.5svh;
  height: 12.5svh;
  border-radius: 50%;
}

.ProfilePage .ProfileDesc {
  margin-left: 1.25%;
  width: 70%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  gap: 5%;
  margin-top: .5%;
}

.ProfilePage .ProfileDesc h2 {
  font-size: 2.5svh;
  margin: 0px 0px 5px 0px;
}

.ProfilePage .ProfileDesc h3 {
  font-size: .88em;
  margin: 0px 0px 5px 0px;

}

.ProfilePage .AllProfile {
  display: flex;
  flex-direction: row;
  min-height: 75svh;
}

.ProfilePage .leftSide {
  margin-left: 5%;
  width: 25%;
  margin-bottom: 2.5%;
}

.ProfilePage .ProfileTag .applybutton {
  width: 33%;
  align-self: center;
}
.bioresumewrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2.5%;
}
.ProfilePage button {
  width: 50%;
  height: 4vh;
  border-radius: 1vh;
  border-style: none;
  color: white;
  background-color: var(--main);
  font-size: 1.75vh;
  cursor: pointer;
}

.ProfilePage .rightside {
  margin-top: 1.25%;
  width: 100%;
  background-color: var(--whiteColor);
  padding-bottom: 5%;
  border-style: solid;
  border-width: 0;
  border-top-width: 1px;
  border-color: #00000030;
}

.leftSide h3 {
  font-size: 2.25vh;
}

#NewProfileInfo h3 {
  font-size: 1.75svh;
}

/* ProfileBio */
.ProfileBio {
  width: 90%;
}

.ProfileBio .EnterBio {
  background-color: var(--whiteColor);
  padding-left: 2.5%;
  padding-top: 0.25vh;
  padding-bottom: 1vh;
  border-radius: 0.25vh;
  border-color: green;
  width: 100%;
}

.Experience .EnterBio {
  width: 100%;
}

.CertificateValue {
  margin-right: 10%;
  /* Adjust the percentage as needed */
}

.Experience .EditDescButton:hover {
  background-color: rgb(156, 83, 83);
}


.EnterBio {
  width: 100%;
}

#CoverBio {
  border-bottom-style: solid;
  border-width: .5px;
  border-color: #00000090;
  padding-bottom: 1%;
}

.JobExp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.JobDem {
  display: flex;
  width: 66%;
  /* justify-content: space-between; */
  gap: 25%;
  padding-left: 6.33%;
  align-items: center;
}

.JobDem .input-title,
.JobDem .input-subtitle,
.input-right-side {
  width: 32%;
}

.title,
.subtitle,
.right-side {
  width: 33%;
  text-align: center;
}

#isEditing {
  width: 15%;
}

#isEditingSpecial {
  width: 5%;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 27.5%;
}

#ProfileSectionNew input,
select {
  border-radius: 10px;
  border-width: .5px;
  padding: 10px;
  padding-right: 15svh;
}


#AppliedJobsArea {
  margin-top: 1.25%;
  display: flex;
  flex-direction: row;
  gap: 2.5%;
  width: 100%;
  justify-content: center;
}

@media only screen and (max-width: 1024px) {
  #isEditingSpecial {
    width: 10%;
    margin: 0;
    padding: 0;
    position: absolute;
    left: 75%;
  }

  #DownloadResume {
    width: 25vh !important;
    border-radius: 10px !important;
  }

  #DownloadText {
    font-size: 1.25svh !important;
  }

  #FileUpload {
    width: 15vh !important;
    padding: 0 !important;
    font-size: 1.25svh !important;
  }
  .ProfilePage button {
    font-size: .99em;
  }
}

@media only screen and (max-width: 768px) {
  #isEditing {
    width: 25%;
    font-size: 1.5svh;
    padding: 0;
  }

  #isEditingSpecial {
    width: 15%;
    margin: 0;
    padding: 0;
    position: absolute;
    left: 75%;
    display: none;
  }

  #SwitcherProfile {
    width: 25vh !important;
    padding: 10% !important;
    text-wrap: nowrap;

  }

}

#TagRow {
  display: flex;
  gap: 2.5%;
}

#TagRow button {
  width: 15svh;
  color: black;
  background-color: #00000010;
  font-weight: 600;
  font-size: 1.5svh;
}

#activeSpecial {
  background-color: var(--main) !important;
  color: white !important;
}

#TagRowLabel {
  font-size: 1.75svh;
  margin-bottom: 2.5%;
  font-weight: 500;
}

#TagRowSubLabel {
  font-size: 1.25svh;
}

#TagRowNewLabel {
  font-size: 1.75svh;
  margin-bottom: 0;
  font-weight: 500;
  margin-top: 2.5%;
}

#SavedJobsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-left: 2.5%;
}
#SavedDesc a{
}
#SavedDesc{
  margin: 0;
  font-size: 1.5svh;
  color: black;
  line-height: 2svh;
}

#SavedTitle{
  font-size: 1.75svh;
  color: var(--main);
  font-weight: 600;
}

#SavedJobsWrapper h4 {
  text-align: left !important;
}

#DisplayJobEmployer {
  border-bottom-style: solid;
  border-width: 1px;
  width: 70svw;
  height: 15svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  margin-bottom: 2.5%;
  border-color: black;
}
#DisplayJobEmployer:hover{
  scale: 1.05;
  border-width: 2px;
  transition: .6s;
}

#DisplayJobEmployer button {
  width: 10svw;
  height: 3.33svh;
}
.SavedJobsText{
  margin-top: 1%;
  width: 12.5%;
  /* border-bottom-style: solid; */
  padding: 1%;
  border-width: .5px;
  margin-bottom: 1.25%;
  background-color: var(--main)75;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.SavedJobsText h2{
  font-weight: 700;
  font-style: italic;
  color: black;
  font-size: 2svh;
}
#HomeJobsEmployer{
  display: flex;
  flex-direction: column;
}