.forgetPassword{
    display: flex;
    justify-content: center;
    align-self: center;
    align-content: center;
    height: 100vh;
    width: 100%;
}

.forgetWrapper{
    align-self: center;
    /* border-style: solid; */
    border-radius: 10px;
    padding: 5%;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    width: 40%;
}

.logoforget{
    display: flex;
    justify-content: center;
}
.forgetPassword form{
    display: flex;
    flex-direction: column;
}
.forgetPassword #resetPassForm{
    flex-direction: column;
    margin-top: 7.5%;
}
.forgetPassword input{
    width: 80%;
    border-radius: 10px;
    padding: 10px;
    align-self: center;
}

.forgetPassword button{
    width: 40%;
    align-self: center;
    margin-top: 5%;
    border-radius: 10px;
    border-style: none;
    padding: 7.5px;
    font-size: 14px;
}


.forgetPassword #resetbutton{
    width: 60%;
    align-self: center;
    margin-top: 5%;
    border-radius: 10px;
    border-style: none;
    padding: 7.5px;
    font-size: 14px;
}
.forgetPassword button:hover{
    color: white;
    transition: .4s;
}