#ProfileSectionNew {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-bottom: 1%;
  padding-top: 2.5%;
  border-bottom: 1px solid #00000030;
  padding-left: 5%;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
}

.table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.tableHeader {
  font-weight: bold;
}

.tableRow {}

.tableCell {
  padding: 8px;
  word-wrap: break-word;
  position: relative;
}

.h3Text {
  font-size: 2svh;
  font-weight: 600;
}

.linkButton {
  cursor: pointer;
  color: var(--main);
  text-decoration: none;
  margin: 0 50px;
}

.tableText {
  font-size: 2svh;
  color: #00000060;
  font-weight: 600;
  text-align: left;
  padding-left: .75%;
}

.removeButton {
  color: red;
  cursor: pointer;
  position: absolute;
  right: 70%;
  top: 50%;
  transform: translateY(-50%);
}

#headerProfileTag {
  background-color: var(--main);
  padding: 0.5%;
  width: 10%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-left: 5%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

#GetToKnowWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

#GetToKnowCompany {
  /* background-color: green; */
  display: flex;
  justify-content: center;
  font-size: 2svh;
  border-bottom-style: solid;
  margin-bottom: 2.5%;
  border-bottom-width: .5px;
  padding-bottom: .5%;
  width: 33%;
}

@media only screen and (max-width: 768px) {
  #GetToKnowCompany {
    width: 67%;
    font-size: 1.75svh;
  }
}

@media only screen and (max-width: 768px) {
  #ProfileSectionNew {
    padding-left: 1%;
  }

  .table {
    width: 97.5%;
    max-width: 100%;
  }

  .tableText {
    font-size: 1.5svh;
    text-wrap: nowrap;
  }

  .h3Text {
    font-size: 1.25svh;
  }

  .linkButton {
    cursor: pointer;
    color: var(--main);
    text-decoration: none;
    margin: 0 50px;
  }

  #headerProfileSection {
    font-size: 1.25svh !important;
    padding: 2.5%;
    padding-left: 1%;
  }

  #headerProfileTag {
    width: 25%;
  }


}